<template>
	<div>
		<ts-page-title :title="$t('loanDropOut.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('loanDropOut.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper>
				<div class="row">
					<div class="col-lg-3">
						<div class="card border-0 bg-gray-900">
							<div class="card-body">
								<div class="text-gray-500">
									<b>{{
										$t('loanDropOut.filterCriteria')
									}}</b>
								</div>
							</div>
							<div class="widget-list rounded-bottom">
								<div class="mt-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t('loanDropOut.branch')
										}}</label>
										<ts-branch-filter @filter="
											value =>
												(model.branch_id = value)
										" :isPlaceholder="true" />
										<div class="text-danger" v-if="errors.has('branch_id')">
											{{ errors.first('branch_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label"></label>
										<ts-checkbox v-model="is_as_of_date">
											{{ $t('loanDropOut.isAsOfDate') }}
										</ts-checkbox>
									</div>
								</div>
								<div class="mb-3" v-if="!is_as_of_date">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t(
																					'loanDropOut.transactionDate'
											)
										}}</label>
										<DatePicker v-model="model.dateRange" type="daterange" placement="bottom-end"
											:placeholder="$t('selectDateRange')" style="width: 100%" :transfer="true"
											format="dd-MM-yyyy" @on-change="
												onChangeDisbursementDateDate
											"></DatePicker>
										<div class="text-danger" v-if="errors.has('dateRange')">
											{{ errors.first('dateRange') }}
										</div>
									</div>
								</div>
								<div class="mb-3" v-if="is_as_of_date">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t('loanDropOut.asOfDate')
										}}</label>
										<DatePicker v-model="model.as_of_date" placement="bottom-end"
											style="width: 100%" :transfer="true" format="dd-MM-yyyy"
											placeholder="Select As of Date"
											@on-change="onChangeAsOfDate"></DatePicker>
										<div class="text-danger" v-if="errors.has('as_of_date')">
											{{ errors.first('as_of_date') }}
										</div>
									</div>
								</div>
								<div class="mb-3" v-if="!is_as_of_date">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{ $t('loanDropOut.co') }}</label>
										<Select v-model="model.employee_id" multiple :max-tag-count="1"
											:filterable="true" :filter-by-label="true" :placeholder="$t('all')">
											<Option v-for="item in employees" :value="item.employee_id"
												:key="item.employee_id" :label="item.employee_name_en">
												{{ item.employee_name_en }}
											</Option>
										</Select>
										<div class="invalid-feedback" v-if="errors.has('employee_id')">
											{{ errors.first('employee_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3" v-if="!is_as_of_date">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t(
																					'loanDropOut.numDayNotDisburse'
											)
										}}</label>
										<input v-model.trim.number="
											model.numDayNotDisburse
										" type="number" class="form-control" :class="{
	'is-invalid': errors.has(
		'numDayNotDisburse'
	)
}" ref="numDayNotDisburse" />
										<div class="invalid-feedback" v-if="
											errors.has('numDayNotDisburse')
										">
											{{
												errors.first(
													'numDayNotDisburse'
												)
											}}
										</div>
									</div>
								</div>
								<div class="mb-3" v-if="!is_as_of_date">
									<div class="col-md-12">
										<label class="form-col-label control-label required">{{
											$t(
																					'loanTransactionSummary.currency'
											)
										}}</label><br />
										<RadioGroup v-model="model.currency_id" size="small">
											<Radio class="mt-1" :label="c.currency_id" border
												v-for="(c, index) in currencies" :key="index">
												{{ c.currency_code }}
											</Radio>
										</RadioGroup>
										<div class="text-danger" v-if="errors.has('currency_id')">
											{{ errors.first('currency_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t( 'loanDropOut.excelTemplate')
										}}</label><br />
										<RadioGroup size="small" v-model="model.excel_type">
											<Radio class="mt-1" :label="c.value" border v-for="(c, index) in excel"
												:key="index">
												{{ c.label }}
											</Radio>
										</RadioGroup>
									</div>
								</div>
								<div class="mb-3 tw-justify-end tw-flex tw-space-x-2">
									<ts-button outline color="success" @click.prevent="exportExcel"
										:waiting="exporting">
										<i class="far fa-file-excel" v-if="!exporting"></i>
										{{ $t('exportExcel') }}</ts-button>
									<ts-button color="danger" outline @click.prevent="preview" :waiting="waiting">
										<i class="far fa-file-pdf" v-if="!waiting"></i>
										{{ $t('previewPdf') }}</ts-button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9">
						<div id="container">
							<ts-preview-pdf v-model="waiting" :src="src" />
						</div>
					</div>
				</div>
			</ts-panel-wrapper>
		</ts-panel>
	</div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'

export default {
	name: 'loanDropOut',
	data() {
		return {
			waiting: false,
			exporting: false,
			errors: new Errors(),
			is_as_of_date: false,
			src: '',
			excel: [
				{
					value: 'standard',
					label: 'Standard'
				},
				{
					value: 'raw_data',
					label: 'Raw Data'
				}
			],
			model: {
				excel_type: 'standard',
				branch_id: [],
				employee_id: [],
				numDayNotDisburse: moment().endOf('month').diff(moment().startOf('month'), 'days') + 1,
				currency_id: null,
				as_of_date: null,
				dateRange: [
					moment()
						.startOf('month')
						.format('DD-MM-YYYY'),
					moment()
						.endOf('month')
						.format('DD-MM-YYYY')
				]
			}
		}
	},
	created() {
		this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
	},
	computed: {
		...mapState('report/resource', ['employees', 'currencies'])
	},
	methods: {
		...mapActions('report/resource', ['getEmployee', 'getCurrency']),
		preview() {
			this.errors = new Errors()
			this.waiting = true
			this.src = ''
			this.$store
				.dispatch(
					'report/creditOperation/loanDropOut',
					Object.assign(
						{
							is_as_of_date: this.is_as_of_date
						},
						this.model
					)
				)
				.then(response => {
					this.src = response.url
				})
				.catch(error => {
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => {
					this.waiting = false
				})
		},
		exportExcel() {
			this.errors = new Errors()
			this.exporting = true

			if (this.model.excel_type == 'standard') {
				this.$store
					.dispatch(
						'report/creditOperation/loanDropOutExportExcel',
						Object.assign(
							{
								is_as_of_date: this.is_as_of_date
							},
							this.model
						)
					)
					.then(response => {
						let filename = trim(
							response.headers['content-disposition']
								.substring(
									response.headers['content-disposition'].indexOf(
										'filename'
									)
								)
								.replace('filename=', ''),
							'"'
						)
						FileDownload(response.data, filename)
					})
					.catch(err => {
						let error = JSON.parse(
							String.fromCharCode.apply(null, new Uint8Array(err))
						)
						this.notice({ type: 'error', text: error.message })
						this.errors = new Errors(error.errors)
					})
					.finally(() => (this.exporting = false))
			}

			if (this.model.excel_type == 'raw_data') {
				this.$store
					.dispatch(
						'report/creditOperation/loanDropOutRawDataCSV',
						this.model
					)
					.then(response => {
						const anchor = document.createElement('a');
						anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(response.data);
						anchor.target = '_blank';
						anchor.download = 'report-loan-drop-out.csv';
						anchor.click();
					})
					.catch(err => {
						let error = JSON.parse(
							String.fromCharCode.apply(null, new Uint8Array(err))
						)
						this.notice({ type: 'error', text: error.message })
						this.errors = new Errors(error.errors)
					})
					.finally(() => (this.exporting = false))
			}
			// 	this.$store
			// 		.dispatch(
			// 			'report/creditOperation/loanDropOutExportExcel',
			// 			Object.assign(
			// 				{
			// 					is_as_of_date: this.is_as_of_date
			// 				},
			// 				this.model
			// 			)
			// 		)
			// 		.then(response => {
			// 			let filename = trim(
			// 				response.headers['content-disposition']
			// 					.substring(
			// 						response.headers['content-disposition'].indexOf(
			// 							'filename'
			// 						)
			// 					)
			// 					.replace('filename=', ''),
			// 				'"'
			// 			)
			// 			FileDownload(response.data, filename)
			// 		})
			// 		.catch(err => {
			// 			let error = JSON.parse(
			// 				String.fromCharCode.apply(null, new Uint8Array(err))
			// 			)
			// 			this.notice({ type: 'error', text: error.message })
			// 			this.errors = new Errors(error.errors)
			// 		})
			// 		.finally(() => (this.exporting = false))
		},
		onChangeDisbursementDateDate(dateRange) {
			this.model.dateRange = dateRange[0] && dateRange[1] ? dateRange : []
			console.log(moment().diff(moment(dateRange[0], "DD-MM-YYYY"), 'days') + 1);
			this.model.numDayNotDisburse = moment().diff(moment(dateRange[0], "DD-MM-YYYY"), 'days') + 1
		},
		onChangeAsOfDate(date) {
			this.model.as_of_date = date
		},
		notice(not) {
			this.$Notice[not.type]({
				title: 'LOAN DROPOUT',
				desc: not.text
			})
		}
	},
	watch: {
		'model.branch_id': debounce(function (value) {
			this.model.employee_id = []
			this.getEmployee({
				branch_id: value
			})
		}, 500),
		is_as_of_date: function (val) {
			if (val) {
				this.model.as_of_date = null
				this.model.dateRange = []
			}
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getCurrency()
		})
	}
}
</script>
